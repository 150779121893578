<template>
  <div class="comp-login">
    <v-dialog v-model="isNewPw" max-width="800">
      <v-card>
        <v-card-text>
          <v-card-title class="pl-0">Adja meg az új jelszavát. </v-card-title>
          <v-form v-model="pwFormValid">
            <v-text-field
              v-model="newpw.primary"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              label="Jelszó"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
            <!--  -->
            <v-text-field
              v-model="newpw.control"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show2 ? 'text' : 'password'"
              label="Megerősítés"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!isPwValid"
            @click="submitNewPw()"
            x-large
            color="secondary"
            class="fp"
            >MEHET</v-btn
          >
          <v-btn @click="isNewPw = false" x-large class="fp"
            >MÉGSEM</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <div class="bg"></div>
    <v-card
      v-if="!isForgotten"
      elevation="2"
      class="pa-12"
      min-width="400"
      max-width="600"
    >
      <v-card-title class="title"
        ><h2>{{ $t("form.login") }}</h2></v-card-title
      >

      <v-card-text>
        <!-- <p>{{ $t("form.loginIntro") }}</p> -->
        <v-text-field
          :label="$t('form.email')"
          placeholder="email"
          outlined
          v-model="user.email"
        ></v-text-field>
        <v-text-field
          label="Jelszó"
          placeholder="******"
          outlined
          type="password"
          v-model="user.password"
          @keydown.enter="attemptAuth()"
        ></v-text-field>
        <p>
          <a href="#" @click.prevent="isForgotten = true">{{
            $t("form.passwordForgottenAction")
          }}</a>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="attemptAuth()" block color="secondary">
          {{ $t("form.login") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="isForgotten"
      elevation="2"
      class="pa-12"
      min-width="400"
      max-width="600"
    >
      <v-card-title class="title"
        ><h2>{{ $t("form.passwordForgottenAction") }}</h2></v-card-title
      >

      <v-card-text>
        <p>Adja meg a regisztrált email címét.</p>
        <v-text-field
          :label="$t('form.email')"
          placeholder="email"
          outlined
          v-model="forgottenEmail"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="submitRecovery()" block color="secondary"> MEHET </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE, DEFAULT_HEADER, PWRESET_BASE } from "../util/constants";

export default {
  data() {
    return {
      showDialog: true,
      isLocked: false,
      user: {
        email: "",
        password: "",
      },
      isForgotten: false,
      isNewPw: false,
      forgottenEmail: null,
      show1: false,
      show2: false,
      newpw: {
        primary: "",
        control: "",
      },
      pwFormValid: false,
      pwToken: null,
      rules: {
        required: (v) => !!v || "Kötelező.",
        min: (v) => v.length >= 8 || "Min 8 karakter",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
          "Nem érvényes",
      },
    };
  },
  mounted() {
    // console.log(this.$jwt.decode());
    if (this.$store.getters.isTokenValid) {
      this.$router.push("/");
    }
    if (this.$route.query.token && this.$route.query.token.length > 0) {
      this.isNewPw = true;
      this.pwToken = this.$route.query.token;
    }
  },
  created() {},
  computed: {
    isValid() {
      // TO-DO: Validate locally
      return this.user.email !== "" && this.user.password !== "";
    },
    isPwValid() {
      return this.pwFormValid && this.newpw.primary === this.newpw.control;
    },
  },
  methods: {
    async submitRecovery() {
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Redirect-Uri": PWRESET_BASE,
        },
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}admin/users/forgotten-password?email=${this.forgottenEmail}`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.text();
          this.isForgotten = false;
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async submitNewPw() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        password: this.newpw.primary,
        token: this.pwToken,
      });
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      let res = await fetch(
        `${API_BASE}admin/users/reset-password`,
        requestOptions
      );

      if (res.ok) {
        this.isNewPw = false;
        setTimeout(() => {
          window.location = window.location.href.split("?")[0];
          window.location.reload();
        }, 200);
        messageBus.$emit("onNewMessage", {
          type: "success",
          label: this.$t("message.success"),
        });
      } else {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: "Sikertelen. Próbálja újra később",
        });
      }
    },
    attemptAuth() {
      if (!this.isLocked && this.isValid) {
        eventBus.$emit("LOAD_ON");
        this.$store.dispatch("logIn", this.user).then((res) => {
          console.log(res);
          if (res) {
            messageBus.$emit("onNewMessage", {
              type: "success",
              label: this.$t("message.loginSuccess"),
            });
            setTimeout(() => {
              location.reload();
            }, 200);
          } else {
            messageBus.$emit("onNewMessage", {
              type: "error",
              label: this.$t("message.loginFailed"),
            });
          }
          eventBus.$emit("LOAD_OFF");
        });
      }
    },
  },
};
</script>

<style scoped>
.title h2 {
  text-transform: uppercase;
  font-weight: 300;
}
.comp-login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  width: 100%;
}
.bg {
  background-image: url("../assets/app_login.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -64px;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
